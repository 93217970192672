import axios from '@axios'
import { ref } from '@vue/composition-api'

export default function useServices() {
  const services = ref([])
  const loading = ref(false)
  const searchService = ref(null)

  const getServices = params => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .post('/services/filter', params)
        .then(response => {
          const { data } = response.data
          services.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  return {
    // vars
    loading,
    searchService,
    services,

    // function
    getServices,
  }
}
