<template>
  <v-dialog
    v-model="servicesEditorDialog"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Vehicle Service</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="8"
            >
              <v-text-field
                v-model="serviceDataLocal.description"
                :error-messages="errors.description"
                label="Service Name"
                dense
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="4"
            >
              <v-text-field
                v-model="serviceDataLocal.cost"
                label="Amount"
                dense
                outlined
                type="number"
                prefix="Php"
                hide-details=""
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="$emit('update:services-editor-dialog', false)"
        >
          Close
        </v-btn>
        <v-btn
          color="success"
          :disabled="saving"
          @click="saveService"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { watch, ref } from '@vue/composition-api'
import axios from '@axios'

export default {
  props: {
    servicesEditorDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    serviceData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const saving = ref(false)
    const errors = ref([])
    const serviceDataLocal = ref({})
    serviceDataLocal.value = JSON.parse(JSON.stringify(props.serviceData))

    const saveService = () => {
      const { description, cost, id } = serviceDataLocal.value
      const formData = { description, cost }
      saving.value = true
      if (id) {
        // update
        axios
          .put(`/services/${id}`, formData)
          .then(_ => {
            saving.value = false
            emit('saveService')
            emit('update:services-editor-dialog', false)
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
            saving.value = false
          })
      } else {
        // new
        axios
          .post('/services', formData)
          .then(_ => {
            saving.value = false
            emit('saveService')
            emit('update:services-editor-dialog', false)
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
            saving.value = false
          })
      }
    }

    watch(
      () => props.servicesEditorDialog,
      () => {
        serviceDataLocal.value = JSON.parse(JSON.stringify(props.serviceData))
        errors.value = []
      },
    )

    return {
      // variables
      errors,
      serviceDataLocal,

      saving,

      // functions
      saveService,
    }
  },
}
</script>
